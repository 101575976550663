@tailwind base;
@tailwind components;
@tailwind utilities;

.markdown {
  @apply max-w-2xl;
}

.markdown a:not([data-router]) {
  @apply text-sky-500 hover:underline;
}

.markdown a .code, .markdown > p > a code {
  @apply bg-gray-200 hover:no-underline;
}

.markdown .code, .markdown > p > code {
  @apply bg-gray-200 text-sm p-1.5 rounded-md text-stone-900;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdown__router-link {
  @apply text-stone-900;
}

.markdown h2 {
  @apply mb-4 text-xl font-bold;
  padding-right: 2.5rem;
}

.markdown h4 {
  @apply mb-4;
}

.markdown p {
  @apply leading-8;
}

.splash {
  @apply w-screen h-screen fixed top-0 left-0 flex justify-center items-center z-50;
  background-color: rgba(0, 0, 0, 0.25);
}

.splash svg {
  width: 200px;
  height: 200px;
}

.accordion {
  @apply w-full bg-darcula mb-5 last:mb-0 rounded-2xl;

  &__summary {
    @apply w-full flex justify-between items-center py-5 px-5 cursor-pointer;

    & svg {
      font-size: 1.25rem;
    }
  }

  &__content {
    @apply flex flex-col py-5 px-5;
  }
}

.nav-menu-button {
  display: none;
}

.nav-close-button {
  display: none;
}

@media (max-width: 900px) {
  .nav-menu-button {
    display: block;
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
     
   & svg {
    font-size: 2rem;
    }
  }
  .navbar {
    padding-top: 6rem;

    &--hidden {
      display: none
    }
  }

  
  .pl-80 { 
    padding: 0;
  }

  .nav-close-button {
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
     
   & svg {
    font-size: 2rem;
    }
  }
}

